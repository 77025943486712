import { filterByFormRef } from "../../utils/constant";

class FilterByForm {
  constructor(card, form, activeCat) {
    this.cards = document.querySelectorAll(card);
    this.mainForm = document.getElementById(form);
    this.activeCat = activeCat;
    this.init();
  }

  init() {
    this.mainForm &&
      this.mainForm.addEventListener("submit", (e) => {
        e.preventDefault();
        this.handleFormSubmit();
      });
  }

  handleFormSubmit() {
    let activeCategory = document.querySelector(this.activeCat);
    let category = activeCategory
      .querySelector("a")
      .getAttribute(filterByFormRef.attr);
    let filterCardsbyCategory = [];

    if (category == filterByFormRef.allAlias) {
      filterCardsbyCategory = this.cards;
    } else {
      this.cards.forEach((item) => {
        if (item.dataset.category == category) {
          filterCardsbyCategory.push(item);
        }
      });
    }

    const formData = new FormData(this.mainForm);

    let arrLength = [];
    let highToLow = false;
    let lowToHigh = false;
    let formEmpty = true;
    let checkBoxChecked=false;
    filterCardsbyCategory.forEach((item) => {
      let push = false;
      for (const pair of formData.entries()) {
        let formField = pair[0];
        let formVal = pair[1];
        if (
          formVal === filterByFormRef.switch &&
          formField != filterByFormRef.indexTarget
        ) {
          formEmpty = false;
          checkBoxChecked=true;
          let attr = item.getAttribute(`data-${formField}`);
          if (attr && attr == filterByFormRef.attrVal) {
            push = true;
          }
        }
      }
      if (push == true) {
        item.classList.remove(filterByFormRef.hideClass);
        arrLength.push(item);
      } else {
        if (formEmpty == false) {
          item.classList.add(filterByFormRef.hideClass);
        } else {
          item.classList.remove(filterByFormRef.hideClass);
        }
      }
    });

    // high to low fee filter

    let newArr = [];
    // let highToLow = false;
    // let lowToHigh = false;

    // filters applied

    filterCardsbyCategory.forEach((item) => {
      for (const pair of formData.entries()) {
        let formField = pair[0];
        let formVal = pair[1];
        let attr = item.getAttribute(`data-${formField}`);
        newArr.push(attr);
        if (formField == filterByFormRef.indexTarget && formVal == 1) {
          formEmpty = true;
          highToLow = true;
        } else if (formField == filterByFormRef.indexTarget && formVal == 0) {
          formEmpty = true;
          lowToHigh = true;
        }
        else{
          formEmpty = false;
        }
      }
    });

    let sortedArray = [];

    if (highToLow && arrLength.length > 0) {
      //   formEmpty = false;
      sortedArray = arrLength.sort(function (a, b) {
        return b - a;
      });
    } else if (highToLow && !arrLength.length) {
      //   formEmpty = false;

      sortedArray = newArr.sort(function (a, b) {
        return b - a;
      });
    } else if (lowToHigh && arrLength.length > 0) {
      //   formEmpty = false;

      sortedArray = arrLength.sort(function (a, b) {
        return a - b;
      });
    } else if (lowToHigh && !arrLength.length > 0) {
      //   formEmpty = false;
      sortedArray = newArr.sort(function (a, b) {
        return a - b;
      });
    }
    let cardArr = [];
   if(checkBoxChecked)
   {
     if(arrLength.length>0)
     {
      sortedArray.forEach((cards, i) => {
        filterCardsbyCategory.forEach((item) => {
          // formEmpty = false;
          let attr = item.getAttribute("data-cardOrder");
          if (cards == attr) {
            cardArr.push(item);
            arrLength.push(item);
            item.style.order = i;
          }
        });
      });
     }
   }
   else{
    sortedArray.forEach((cards, i) => {
      filterCardsbyCategory.forEach((item) => {
        // formEmpty = false;
        let attr = item.getAttribute("data-cardOrder");
        if (cards == attr) {
          cardArr.push(item);
          arrLength.push(item);
          item.style.order = i;
        }
      });
    });
   }
    
  

    let noData = document.querySelector(filterByFormRef.nodataWrap);
    if (arrLength.length < 1) {
      if (formEmpty == true) {
        if (filterCardsbyCategory.length < 1) {
          noData.style.display = "block";
        } else {
          noData.style.display = "block";
        }
      } else {
        noData.style.display = "block";
      }
    } else {
      noData.style.display = "none";
    }

    const filterModal = document.getElementById(filterByFormRef.popup);
    const backdrop = document.getElementById(filterByFormRef.backdrop);

    filterModal && filterModal.classList.remove(filterByFormRef.popupClass);
    backdrop && backdrop.classList.remove(filterByFormRef.popupClass);
    document.querySelector("html").classList.remove(filterByFormRef.htmlClass);
  }
}

export default FilterByForm;
