class SwiperSlider {
  constructor(slider) {
    this.slider = slider;
    this.swiper = null;
    this.screenWidthEm = parseInt(window.innerWidth / 120);

    this.slidesParam = {
      observeParents: true,
      observer: true,
      watchSlidesProgress: true,
      slidesPerView: this.slider.getAttribute("data-slidesPerView") || 1,
      spaceBetween: this.slider.getAttribute("data-spaceBetween") || 0,
      loop: this.slider.getAttribute("data-sliderLoop") ? true : false,
      centeredSlides: this.slider.getAttribute("data-centeredSlides")
        ? true
        : false,
      slideToClickedSlide: this.slider.getAttribute("data-slideToClickedSlide")
        ? true
        : false,
    };

    // Rewind
    if (this.slider.getAttribute("data-rewind")) {
      this.slidesParam.rewind = this.slider.getAttribute("data-rewind");
    }

    // Free Mode
    if (this.slider.getAttribute("data-freemode")) {
      this.slidesParam.freeMode = true;
    }

    // Slide Change Speed
    if (this.slider.getAttribute("data-sliderSpeed")) {
      this.slidesParam.speed = this.slider.getAttribute("data-sliderSpeed");
    }

    // Slide Effect
    if (this.slider.getAttribute("data-effect")) {
      this.slidesParam.effect = this.slider.getAttribute("data-effect");
      if (this.slider.getAttribute("data-crossFade")) {
        this.slidesParam.fadeEffect = {
          crossFade: true,
        };
      }
    }

    // Slider Pagination
    if (this.slider.getAttribute("data-pagination") === "true") {
      let paginationClass = this.slider.getAttribute("data-paginationClass")
        ? `.${this.slider.getAttribute("data-paginationClass")} `
        : "";
      this.slidesParam.pagination = {
        el: `${paginationClass} .swiper-pagination`,
        clickable: true,
        dynamicBullets: this.slider.getAttribute("data-dynamicBullets")
          ? true
          : false,
      };
    }

    // Slider Scrollbar
    if (this.slider.getAttribute("data-scrollbar") === "true") {
      let scrollbarClass = this.slider.getAttribute("data-scrollbarClass")
        ? `.${this.slider.getAttribute("data-scrollbarClass")} `
        : "";
      this.slidesParam.scrollbar = {
        el: `${scrollbarClass} .swiper-scrollbar`,
        clickable: true,
      };
    }

    // Slider Coverflow Effect
    if (this.slider.getAttribute("data-coverflowEffect")) {
      let incomingString = JSON.parse(
        this.slider.getAttribute("data-coverflowEffect")
      );

      this.slidesParam.coverflowEffect = {
        rotate: 0,
        scale: +incomingString.scale,
        depth: +incomingString.depth,
        stretch: +incomingString.stretch,
        modifier: 1,
        slideShadows: false,
      };
      if (this.slider.getAttribute("data-cover-center")) {
        let slideCount = this.slider.querySelectorAll(".swiper-slide").length;
        if (slideCount > 2) {
          if (slideCount % 2 === 0) {
            this.slidesParam.initialSlide = slideCount / 2;
          } else {
            this.slidesParam.initialSlide = slideCount / 2 - 0.5;
          }
        }
      }
    }

    // Slider Navigation
    if (this.slider.getAttribute("data-navigation") === "true") {
      let navigationClass = this.slider.getAttribute("data-navigationClass")
        ? `.${this.slider.getAttribute("data-navigationClass")} `
        : "";
      this.slidesParam.navigation = {
        nextEl: `${navigationClass} .swiper-button-next`,
        prevEl: `${navigationClass} .swiper-button-prev`,
      };
    }

    // Slider Autoplay
    if (this.slider.getAttribute("data-autoplay") === "true") {
      if (this.slider.getAttribute("data-pauseonHover") == "true") {
        this.slidesParam.autoplay = {
          delay: this.slider.getAttribute("data-autoplaySpeed") || 3000,
          disableOnInteraction: true,
        };
      } else {
        this.slidesParam.autoplay = {
          delay: this.slider.getAttribute("data-autoplaySpeed") || 3000,
          disableOnInteraction: false,
        };
      }
    }

    // Slider center slide feature
    if (this.slider.getAttribute("data-centeredSlides") === "true") {
      this.slidesParam.centeredSlides = true;
    }

    // Slider Video Loading
    if (this.slider.getAttribute("data-videoLoader") === "true") {
      this.slidesParam.on = {
        init: ({ realIndex, slides, pagination }) => {
          this.addIframe(realIndex, slides);
          this.changeSliderProgressTime(realIndex, slides, pagination);
        },
        beforeSlideChangeStart: ({ realIndex, slides }) => {
          this.removeIframe(realIndex, slides);
        },
        beforeTransitionStart: ({
          slides,
          pagination,
          snapIndex,
        }) => {
          this.changeSliderProgressTime(snapIndex, slides, pagination);
        },
        slideChangeTransitionEnd: ({
          slides,
          snapIndex
          }) => {
          this.addIframe(snapIndex, slides);
         },
      };
    }

    // Slider Break Points
    if (this.slider.getAttribute("data-breakpoints")) {
      let parsedObjects = JSON.parse(
        this.slider.getAttribute("data-breakpoints")
      );

      let stretch;
      let depth;
      // !parsedObjects["1200"].coverflowEffect
      if (parsedObjects["1200"] && parsedObjects["1200"].coverflowEffect) {
        depth =
          (parsedObjects["1200"].coverflowEffect.depth / 16) *
          this.screenWidthEm;
        stretch =
          (parsedObjects["1200"].coverflowEffect.stretch / 16) *
          this.screenWidthEm;
        parsedObjects["1200"].coverflowEffect.rotate = 0;
        parsedObjects["1200"].coverflowEffect.depth = +depth;
        parsedObjects["1200"].coverflowEffect.stretch = +stretch;
        parsedObjects["1200"].coverflowEffect.scale =
          +parsedObjects["1200"].coverflowEffect.scale;
        parsedObjects["1200"].coverflowEffect.modifier = 1;
        parsedObjects["1200"].coverflowEffect.slideShadows = false;
      }

      this.slidesParam.breakpoints = {
        ...parsedObjects,
      };
    }

    // Slider has THumb
    if (this.slider.getAttribute("data-thumbSliderId")) {
      let getSwiperThumb = document.getElementById(
        `${this.slider.getAttribute("data-thumbSliderId")}`
      );
      if (getSwiperThumb) {
        this.slidesParam.thumbs = {
          swiper: getSwiperThumb.swiper,
        };
      }
    }

    // Slider has disable on desktop or ipad
    if (this.slider.getAttribute("data-disableDesktop")) {
      if (window.innerWidth < 1200) {
        this.initSwiper();
      }
    } else if (this.slider.getAttribute("data-disableIpad")) {
      if (window.innerWidth < 767) {
        this.initSwiper();
      }
    } else {
      this.initSwiper();
    }
    // MutationObserver to detect changes in the lang attribute
    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "lang"
        ) {
          this.reinitializeSwiper();
          break;
        }
      }
    });

    // Configure the MutationObserver to observe changes in the lang attribute
    const observerConfig = { attributes: true, attributeFilter: ["lang"] };
    observer.observe(document.documentElement, observerConfig);
  }

  addIframe(index, slides) {
    let videoContainer = slides[index].querySelector(".iframeData");
    if (videoContainer) {
      let videoType = videoContainer.getAttribute("data-video-type");
      let videoElement;
      if (videoType == "iframe") {
        videoElement =
          '<iframe class="iframeVidz" src="' +
          videoContainer.getAttribute("data-src") +
          '"></iframe>';
      } else {
        videoElement =
          '<video class="iframeVidz" autoplay="autoplay" muted loop playsinline>' +
          '<source src="' +
          videoContainer.getAttribute("data-src") +
          '" type="video/mp4" /></video>';
      }
      videoContainer.innerHTML = videoElement;
    }
  }

  removeIframe(index, slides) {
    let videoElement = slides[index].querySelector(".iframeVidz");
    if (videoElement) {
      videoElement.remove();
    }
  }

  changeSliderProgressTime(index, slides, pagination) {
    let activeSlide = slides[index];
    let swiperAutoPlayTime = activeSlide.dataset.swiperAutoplay;
    if (swiperAutoPlayTime) {
      let progressBarTime = swiperAutoPlayTime / 1000 + "s";
      let activeBullet = pagination.bullets[index];
      activeBullet.style.setProperty("--cubic-bezier-values", progressBarTime);
    }
  }

  initSwiper() {
    if (
      this.slider &&
      this.slider.children &&
      this.slider.children.length > 0
    ) {
      this.swiper = new Swiper(this.slider, {
        ...this.slidesParam,
      });
      if (this.slider.getAttribute("data-pauseOnHover") == "true") {
        this.slider.addEventListener("mouseover", (event) => {
          this.slider.swiper.autoplay.stop();
        });
        this.slider.addEventListener("mouseleave", (event) => {
          this.slider.swiper.autoplay.start();
        });
      }
    }
  }

  reinitializeSwiper() {
    if (this.swiper) {
      this.swiper.destroy(); // Destroy current Swiper instance
    }
    this.initSwiper(); // Initialize Swiper again
  }
}

export default SwiperSlider;
