import {
    deviceWidth
} from "../../utils/constant";

class ShowHide {
    constructor(handler, closeHandler, deviceOnly = '', noanchor = false, backdrop = null) {
        this.handleBtn = document.querySelector(handler);
        this.deviceOnly = deviceOnly;
        this.noanchor = noanchor;
        this.closeHandler = closeHandler;
        // Backdrop Added in the popup 23 Jan
        this.backdrop = backdrop;
        this.targetBackdrop = null;
        this.closeBtn = null;
        this.targetContainer = null;
        this.init();
    }

    init() {
        if (this.handleBtn) {
            this.targetContainer = document.getElementById(this.handleBtn.getAttribute('data-ref'));

            this.handleBtn.addEventListener("click", this.openContainer.bind(this));

            if (this.targetContainer) {
                this.closeBtn = this.targetContainer.querySelector(this.closeHandler)
            }
        }

        if (this.closeBtn) {
            this.closeBtn.addEventListener("click", this.closeContainer.bind(this));
        }

        // Backdrop Updated in the popup 29 Jan
        this.targetBackdrop = document.querySelector(this.backdrop);
        if (this.targetBackdrop) {
            this.targetBackdrop.addEventListener("click", this.closeBackdrop.bind(this));
        }


    }

    openContainer(e) {
        if (this.deviceOnly == "mobile") {
            if (window.innerWidth < deviceWidth.mobile) {
                this.openContainerAction(e)
            }
        } else if (this.deviceOnly == 'ipad') {
            if (window.innerWidth < deviceWidth.ipadpro) {
                this.openContainerAction(e)
            }
        } else if (this.deviceOnly == 'desktop') {
            if (window.innerWidth > deviceWidth.desktop) {
                this.openContainerAction(e)
            }
        } else {
            this.openContainerAction(e);
        }
    }

    openContainerAction(current) {
        if (this.noanchor) {
            current.stopPropagation();
        }
        if (this.targetContainer) {
            this.targetContainer.classList.add('open');
            this.targetContainer.classList.remove('close');
        }
        // Backdrop Added in the popup 23 Jan
        if (this.targetBackdrop) {
            this.targetBackdrop.classList.add('open');
            this.handleBtn.closest("html").classList.add("overflow-hidden");
        }
    }

    closeContainer() {
        if (this.targetContainer) {
            this.targetContainer.classList.remove('open');
            this.targetContainer.classList.add('close');
        }
        if (this.targetBackdrop) {
            // Backdrop Added in the popup 23 Jan
            this.targetBackdrop.classList.remove('open');
            this.handleBtn.closest("html").classList.remove("overflow-hidden");
        }
    }

    closeBackdrop() {
        if (this.targetBackdrop) {
            this.targetContainer.classList.remove('open');
            this.targetBackdrop.classList.remove('open');
            this.handleBtn.closest("html").classList.remove("overflow-hidden");
        }
    }


}

export default ShowHide